.App {
  text-align: center;
}

body {
  background-color: black;
}

/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  height: 100%;
  background-color: black;
}

.wrapper {
  background-color: #000;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login {
  height: 100vh;
  background: rgb(29,26,43);
  background: linear-gradient(180deg, rgba(29,26,43,1) 0%, rgba(42,20,33,1) 35%, #342b1c 100%);
}

.container {
  margin: 0px 3%;
}

.d-flex {
  display: flex;
} 
.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.cardTransparente {
  background-color:  rgba(66, 48, 59, 0.342);
  color: white;
  margin: 50px 0px 20px 0px;
  padding: 20px 5px;
  border-radius: 10px;
}

.cardTransparente h3 {
  font-size: 17px;
  margin: 0;
}

.inputLogin {
  background-color: black;
  color: white;
  font-weight: normal;
  border-radius: 10px;
  padding: 13px;
  border: 1px solid #464646;
  margin-bottom: 15px;
}

.inputLogin::placeholder {
  color: rgb(168, 168, 168);
}

.buttonBlue {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 13px;
  font-weight: bold;
  background-color: #7C3AED;
  border: none;
  color: white;
  font-size: 17px;
  border-radius: 10px
}

.labelError {
  color: rgb(248, 248, 248);
  font-size: 13px;
  padding-bottom: 5px;
}

.header {
  background-color: black;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.saldo {
  background-color: #7C3AED;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  width: fit-content;
  padding: 10px;
  min-width: 100px;
}

.header img {
  width: 150px;
  height: 100%;
}

.bottomMenu {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  color: white;
  width: 100%;
  background-color: black;
  align-items: center;
  padding: 15px 0px;
}

.publiWrapper {
  display: flex;
  flex-direction: column;
  color: white;
}

.headerPubli {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.headerPubli div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.headerPubli h2 {
  font-weight: normal;
  font-size: 17px;
}

.headerPubli img {
  border-radius: 50%;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.interesse {
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgb(42, 43, 43);
  color: white;
  font-weight: 200;
}
.interesse p{
  text-align: left;
  font-size: 15px;
  margin: 0px;
  width: 95%;
  font-weight: bold;
  color: white;
  padding: 13px 10px;
}

.btns-interesse {
  display: flex;
  width: 95%;
  margin: 0 auto;
  gap: 15px;
}

.nao {
  background-color: red;
  display: flex;
  width: 100%;
  border-radius: 8px;
  color: white;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor:pointer;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.sim {
  background-color: rgba(0, 175, 0, 0.808);
  display: flex;
  width: 100%;
  border-radius: 8px;
  color: white;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  cursor:pointer;
  border: none;
  font-size: 16px;

  font-weight: bold;
}

.post {
  width: 100%;
  max-height: 45vh;
}

.ReactModal__Overlay {
  transition: opacity .5s; /* Opacity will fade smoothly */
  position: fixed;
  inset: 0px;
  background-color: rgba(31, 30, 30, 0.75) !important;
}

.ReactModal__Overlay p {
  color: white;
  font-weight: bold;
}

.ReactModal__Overlay .interesse p {
  text-align: center !important;
}

.buttonBlack {
  background-color: #1A1A1A;
  color: white;
  font-weight: bold;
  border: none;
  height: 40px;
  font-size: 14px;
  margin-top: 15px;
  border-radius: 10px;
}

.btnDourado {
  border-radius: 10px;
  font-size: 17px;
  background: rgb(222,214,157);
  background: linear-gradient(90deg, rgba(222,214,157,1) 0%, rgba(145,113,44,1) 50%, rgba(228,168,46,1) 100%);
  color: white;
  font-weight: bold;
  border: none;
  height: 40px;
}


.loading {
  animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.95);
	}
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms linear;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.cardGrey {
  background-color: #1A1A1A;
  color: white;
  border-radius: 10px;
  padding: 10px 0px;
}

.cardGrey h3 {
  color: grey;
  font-weight: normal;
  margin: 0px;
}

.cardGrey h2 {
  font-size: 34px;
  margin: 10px 0px;
}

.saqueWrapper {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
}

.wrapperOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option {
  background-color: #1A1A1A;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  height: 100px;
  font-size: 17px;
  font-weight: bold;
}

.option.active {
  background-color: #7C3AED;
}

.d-none {
  display: none;
}

.container input {
  background-color: transparent;
  color: white;
  font-weight: bold;
  margin: 20px 0px;
  border: 1px solid rgb(109, 109, 109);
  border-radius: 7px;
  padding: 10px 7px;
  font-size: 17px;
}


.container input::placeholder {
  color: rgb(139, 139, 139);
  font-weight: 500;
}

input:focus {
  border: 1px solid #bbbbbb;
  outline: none;
}


.wrapperBar {
  border: none;
}

.containerBar {
  margin: 10px 0px;
  background-color: #4d4d4d;
  border-radius: 10px;
}

.barCompletedBar{
  border-radius: 10px;
  background-color: #7C3AED;
  width: 10%;
}

.labelBar {
  font-size: 17px;
  font-weight: bold;
  color: white;
}

.termosWrapper {
  border: 1px solid rgb(51, 51, 51);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex; 
  flex-direction: column;
}

.termosWrapper img {
  max-width: 150px;
  margin: 0 auto;
}

.termosWrapper h2 {
  color: white;
  font-weight: 600;
}


.termosWrapper h3 {
  margin: 5px 0px;
  text-align: left;
  color: white;
  font-weight: 600;
}

.termosWrapper p {
  font-size: 12px;
  color: grey;
  text-align: left;
}

.disabled {
  pointer-events: none;
  background-color: #7637e28f;
  color: rgb(161, 160, 160);
}

.superpost {
  background: rgb(222,214,157);
  background: linear-gradient(90deg, rgba(222,214,157,1) 0%, rgba(145,113,44,1) 50%, rgba(228,168,46,1) 100%);
  color: black;
  padding: 5px 10px;
  border-radius: 7px;
  font-weight: bold;
  font-size: 14px;
}

.novo {
  position: absolute;
  left: 55%;
  background-color: #7C3AED;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 30px;
  top: -3px;
}